import React from 'react';
import { Link } from 'react-router-dom';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
    <section id="sidebar">
        <section id="intro">
            <Link to="/" className="logo">
                <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
            </Link>
            <header>
                <h2>曹欣韵</h2>
                <p>
                    <a href="mailto:me@ixinyu.cc">me@ixinyu.cc</a>
                </p>
            </header>
        </section>

        <section className="blurb">
            {/* <h2>关于</h2> */}
            <p>
                你好，我是欣韵，目前还是一个小宝宝，现住于北京。
            </p>
            <ul className="actions">
                <li>
                    <Link to="/about" className="button">
                        关于我
                    </Link>
                </li>
            </ul>
        </section>
    </section>
);

export default SideBar;
