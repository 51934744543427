const routes = [
  {
    index: true,
    label: "欣韵的小站",
    path: '/',
  },
  {
    label: '关于我',
    path: '/about',
  },
  {
    label: '相册集',
    path: '/albums'
  },
  {
    label: '我的足迹',
    path: '/travel'
  },
  {
    label: '我的图表',
    path: '/chart'
  },
  {
    label: '我的家庭',
    path: '/family',
  },
];

export default routes;
