// components/Footer.js
import React from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';

const Footer = () => {
    return (
        <footer>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="lg">
                    <Typography variant="body2" color="text.secondary" align="center">
                        © {new Date().getFullYear()} 欣韵的个人网站
                    </Typography>
                    {/* <Divider sx={{ my: 2 }} /> */}
                </Container>
            </Box>
        </footer>
    );
};

export default Footer;