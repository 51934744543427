import React from 'react';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Analytics from '../components/Template/Analytics';
import Navigation from '../components/Template/Navigation';
import SideBar from '../components/Template/SideBar';
import ScrollToTop from '../components/Template/ScrollToTop';
import Footer from './Footer';

const Main = (props) => (
    <HelmetProvider>
        <Analytics />
        <ScrollToTop />
        <Helmet
            titleTemplate="%s | 欣韵"
            defaultTitle="欣韵"
            defer={false}
        >
            {props.title && <title>{props.title}</title>}
            <meta name="description" content={props.description} />
        </Helmet>
        <div id="wrapper">
            <Navigation />
            <div id="main">{props.children}</div>
            {props.fullPage ? null : <SideBar />}
        </div>
        <Footer />
    </HelmetProvider>
);

Main.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    fullPage: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
};

Main.defaultProps = {
    children: null,
    fullPage: false,
    title: null,
    description: "欣韵的个人网站",
};

export default Main;
