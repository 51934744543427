import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Family = lazy(() => import('./pages/Family'));
const Albums = lazy(() => import('./pages/Albums'));
const AlbumDetail = lazy(() => import('./pages/AlbumDetail'));
// const LiveChart = lazy(() => import('./pages/LiveChart'));
const Chart = lazy(() => import('./pages/Chart'));
const Travel = lazy(() => import('./pages/Travel'));

const App = () => (
    <BrowserRouter basename={PUBLIC_URL}>
        <Suspense fallback={<Main />}>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/about" element={<About />} />
                <Route path="/family" element={<Family />} />
                <Route path="/albums" element={<Albums />} />
                <Route path="/albums/:id" element={<AlbumDetail />} />
                {/* <Route path="/chart" element={<LiveChart />} /> */}
                <Route path="/chart" element={<Chart />} />
                <Route path="/travel" element={<Travel />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    </BrowserRouter>
);

export default App;
